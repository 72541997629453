<template>
  <div v-loading="loading" class="bg-white h-full">
    <div class="flex justify-between p-3 border-bottom pb-2" v-if="mdt_disease">
      <div class="flex">
        <a
          href="javascript:;"
          class="fs-18 txt-grey-600"
          @click="backToMdtDetail"
        >
          {{ mdt_room ? mdt_room.name : "" }}
          <!-- <span
            class="spinner-border"
            role="status"
            v-if="!mdt_room"
          >
            <span class="sr-only">Loading...</span>
          </span>-->
        </a>
        <div
          v-if="
            mdt_disease && mdt_disease.mdt_session_id && mdt_disease.mdt_date
          "
        >
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="mx-2"
          >
            <path
              d="M8.29492 17.09L12.8749 12.5L8.29492 7.91L9.70492 6.5L15.7049 12.5L9.70492 18.5L8.29492 17.09Z"
              fill="#667085"
            />
          </svg>
          <a
            href="javascript:;"
            class="fs-18 txt-grey-600"
            @click="backToMdtSession(mdt_disease.mdt_session_id)"
          >
            {{ $t("home_page.lbl_date") }}
            {{ mdt_disease.mdt_date && formatDate(mdt_disease.mdt_date) }}
          </a>
        </div>
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="mx-2"
        >
          <path
            d="M8.29492 17.09L12.8749 12.5L8.29492 7.91L9.70492 6.5L15.7049 12.5L9.70492 18.5L8.29492 17.09Z"
            fill="#667085"
          />
        </svg>
        <a href="javascript:;" class="fs-18 txt-grey-900 mb-0 disabled">
          {{ $t("multidisciplinary_board.lbl_case_info") }}
        </a>
      </div>
      <div>
        <svg
          v-if="
            this.$route.query.type === 'bienban' ||
            this.$route.query.type === 'TQM'
          "
          @click="closeMR"
          class="mr-1 cursor-pointer"
          width="40"
          height="40"
          viewBox="0 0 29 29"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.391 14.4999L21.4077 9.49489C21.6274 9.2752 21.7508 8.97724 21.7508 8.66656C21.7508 8.35587 21.6274 8.05791 21.4077 7.83822C21.188 7.61854 20.89 7.49512 20.5794 7.49512C20.2687 7.49512 19.9707 7.61854 19.751 7.83822L14.746 12.8549L9.74102 7.83822C9.52134 7.61854 9.22337 7.49512 8.91269 7.49512C8.602 7.49512 8.30404 7.61854 8.08436 7.83822C7.86467 8.05791 7.74125 8.35587 7.74125 8.66656C7.74125 8.97724 7.86467 9.2752 8.08436 9.49489L13.101 14.4999L8.08436 19.5049C7.97501 19.6133 7.88821 19.7424 7.82898 19.8846C7.76975 20.0267 7.73926 20.1792 7.73926 20.3332C7.73926 20.4872 7.76975 20.6397 7.82898 20.7819C7.88821 20.9241 7.97501 21.0531 8.08436 21.1616C8.19281 21.2709 8.32185 21.3577 8.46402 21.4169C8.60619 21.4762 8.75868 21.5067 8.91269 21.5067C9.0667 21.5067 9.21919 21.4762 9.36136 21.4169C9.50353 21.3577 9.63257 21.2709 9.74102 21.1616L14.746 16.1449L19.751 21.1616C19.8595 21.2709 19.9885 21.3577 20.1307 21.4169C20.2729 21.4762 20.4253 21.5067 20.5794 21.5067C20.7334 21.5067 20.8859 21.4762 21.028 21.4169C21.1702 21.3577 21.2992 21.2709 21.4077 21.1616C21.517 21.0531 21.6038 20.9241 21.6631 20.7819C21.7223 20.6397 21.7528 20.4872 21.7528 20.3332C21.7528 20.1792 21.7223 20.0267 21.6631 19.8846C21.6038 19.7424 21.517 19.6133 21.4077 19.5049L16.391 14.4999Z"
            fill="black"
          />
        </svg>
      </div>
    </div>
    <div class="container radius-10 bg-white p-3">
      <div v-if="mr_id && mdt_disease.name" class="px-3 mb-3">
        <p class="fs-18 fw-600 txt-grey-900 mb-0">
          {{ $t("multidisciplinary_board.lbl_case_name") }}
        </p>
        <div class="d-flex">
          <p class="fs-16 mb-0 w-75 max-line-1 fw-500 txt-grey-900">
            {{
              mdt_disease.patient_name + "/ " + mdt_disease.patient_diagnostic
            }}
          </p>
        </div>
      </div>
      <div class="px-3" v-if="mdt_disease">
        <div
          class="mb-3"
          :set="(sttClass = mdtUtils.getStatusDisease(mdt_disease.status))"
        >
          <p class="fs-18 txt-grey-600 mb-2">
            {{ $t("health_book_page.lbl_hd_status") }}
            <span
              class="fs-18 fw-600 text-right mb-1 uppercase"
              :class="sttClass.class"
              >{{ sttClass.text }}</span
            >
          </p>
          <div class="d-flex w-100 justify-content-between">
            <button
              class="btn bg-pri bd-pri text-white px-3"
              v-if="
                mdt_disease &&
                mdt_disease.concluding &&
                (isOwner || master) &&
                !sended &&
                mdt_disease.status == 2
              "
              @click="onSenToOrg(mdt_disease)"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.49902 14.3751V17.5001H5.62402L14.8407 8.28346L11.7157 5.15846L2.49902 14.3751ZM17.2574 5.8668C17.5824 5.5418 17.5824 5.0168 17.2574 4.6918L15.3074 2.7418C14.9824 2.4168 14.4574 2.4168 14.1324 2.7418L12.6074 4.2668L15.7324 7.3918L17.2574 5.8668Z"
                  fill="white"
                />
              </svg>
              {{ $t("multidisciplinary_board.btn_submit_org") }}
            </button>
            <p class="fs-16 fw-500 txt-pri" v-if="sended">
              {{ $t("multidisciplinary_board.lbl_trased_org") }}
            </p>
            &nbsp;
            <div class="flex gap-3 w-full justify-end">
              <button
                v-if="mdt_disease.status < 2"
                :disabled="!isOwner || loading || !mdt_disease.concluding"
                class="btn-action btn bg-pri bd-pri text-white"
                @click="handleUpdateStatusPatient(2)"
              >
                <span
                  class="spinner-border spinner-border-sm me-2"
                  role="status"
                  v-if="loading"
                ></span>
                <span v-else>{{
                  $t("multidisciplinary_board.btn_approve")
                }}</span>
              </button>
              <button
                v-if="mdt_disease.status === 2 && !hasSigned"
                :disabled="!isOwner || loading"
                class="btn-action btn bg-pri bd-pri text-white"
                @click="handleUpdateStatusPatient(0)"
              >
                <span
                  class="spinner-border spinner-border-sm me-2"
                  role="status"
                  v-if="loading"
                ></span>
                <span v-else>{{
                  $t("multidisciplinary_board.btn_cancel_approve")
                }}</span>
              </button>
              <button
                :disabled="!mdt_room"
                :class="!mdt_room ? 'pointer-events-none' : ''"
                class="btn bg-pri bd-pri text-white px-3"
                v-if="mdt_disease"
                @click="showModalCopyMdtDisease"
              >
                {{ $t("multidisciplinary_board.lbl_copy") }}
              </button>
              <button
                :class="!mdt_room ? 'pointer-events-none' : ''"
                :disabled="
                  !mdt_room ||
                  !mdt_disease.person_id ||
                  mdt_disease.status === 2 ||
                  hasSigned
                "
                class="btn bg-pri bd-pri text-white px-3"
                v-if="mdt_disease && isOwner"
                @click="onUpdate"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.49902 14.3751V17.5001H5.62402L14.8407 8.28346L11.7157 5.15846L2.49902 14.3751ZM17.2574 5.8668C17.5824 5.5418 17.5824 5.0168 17.2574 4.6918L15.3074 2.7418C14.9824 2.4168 14.4574 2.4168 14.1324 2.7418L12.6074 4.2668L15.7324 7.3918L17.2574 5.8668Z"
                    fill="white"
                  />
                </svg>
                {{ $t("multidisciplinary_board.lbl_modify") }}
              </button>
            </div>
          </div>
        </div>
        <!-- kết luận -->
        <div class="mb-3 row">
          <div class="col-lg-6 mb-3">
            <div class="bg--E7EAF5 p-3 radius-10 d-flex">
              <svg
                width="60"
                height="60"
                viewBox="0 0 60 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M35 5H15C12.25 5 10.025 7.25 10.025 10L10 50C10 52.75 12.225 55 14.975 55H45C47.75 55 50 52.75 50 50V20L35 5ZM40 45H20V40H40V45ZM40 35H20V30H40V35ZM32.5 22.5V8.75L46.25 22.5H32.5Z"
                  fill="#20409B"
                />
              </svg>

              <div class="w-100 pl-3 cc-tt-content">
                <div class="flex justify-between items-center">
                  <p class="fs-24 fw-500 mb-1 txt-grey-900 max-line-1">
                    {{ $t("multidisciplinary_board.lbl_mdt_report") }}
                  </p>
                  <p
                    v-if="mdt_disease.concluding && mdt_disease.concluding.sign"
                    class="signed-class"
                  >
                    Đã ký
                  </p>
                </div>
                <p class="fs-14 txt-grey-800" v-if="mdt_disease.concluding">
                  <span>
                    {{ $t("multidisciplinary_board.lbl_last_update") }}:
                    {{ formatHmDMY(mdt_disease.concluding.updated_at) }}
                  </span>
                </p>
                <div class="flex gap-2 items-center">
                  <div
                    v-if="
                      mdt_disease.concluding && mdt_disease.concluding.multilang
                    "
                  >
                    <el-dropdown class="mr-3 w-full">
                      <button
                        class="flex items-center justify-between btn bg-inherit bd-pri border txt-pri w-100"
                      >
                        <span>
                          <svg
                            width="25"
                            height="24"
                            viewBox="0 0 25 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12.75 6.5C16.54 6.5 19.92 8.63 21.57 12C19.92 15.37 16.54 17.5 12.75 17.5C8.96 17.5 5.58 15.37 3.93 12C5.58 8.63 8.96 6.5 12.75 6.5ZM12.75 4.5C7.75 4.5 3.48 7.61 1.75 12C3.48 16.39 7.75 19.5 12.75 19.5C17.75 19.5 22.02 16.39 23.75 12C22.02 7.61 17.75 4.5 12.75 4.5ZM12.75 9.5C14.13 9.5 15.25 10.62 15.25 12C15.25 13.38 14.13 14.5 12.75 14.5C11.37 14.5 10.25 13.38 10.25 12C10.25 10.62 11.37 9.5 12.75 9.5ZM12.75 7.5C10.27 7.5 8.25 9.52 8.25 12C8.25 14.48 10.27 16.5 12.75 16.5C15.23 16.5 17.25 14.48 17.25 12C17.25 9.52 15.23 7.5 12.75 7.5Z"
                              fill="#20419B"
                            />
                          </svg>
                        </span>
                        <span>
                          {{ $t("multidisciplinary_board.lbl_view") }}
                        </span>
                        <i
                          class="el-icon-arrow-down el-icon--right fs-16 fw-600"
                        ></i>
                      </button>

                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                          v-for="(item, index) in mdt_disease.concluding
                            .multilang"
                          :key="index"
                          :disabled="
                            checkMultiLangData(
                              LANGUAGE_CODES_FOR_DISEASE[Number(item.lang)]
                            ) || !item.file_name_printing
                          "
                        >
                          <div
                            @click="
                              handleOpenMdtConcluding(
                                item,
                                ENVELOPE_DOCUMENT_TYPE.MDT_FORM
                              )
                            "
                          >
                            {{
                              $t(
                                `common.${appUtils.getLangCode(
                                  Number(item.lang)
                                )}`
                              )
                            }}
                          </div>
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                  <div v-if="isOwner && mdt_disease.status !== 2">
                    <button
                      class="btn bg-pri bd-pri text-white w-100"
                      @click="showDiseaseConcluding(true)"
                    >
                      <span>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.99902 17.2505V21.0005H6.74902L17.809 9.94055L14.059 6.19055L2.99902 17.2505Z"
                            fill="white"
                          />
                          <path
                            d="M20.709 5.63055L18.369 3.29055C17.979 2.90055 17.349 2.90055 16.959 3.29055L15.129 5.12055L18.879 8.87055L20.709 7.04055C21.099 6.65055 21.099 6.02055 20.709 5.63055Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                      {{
                        mdt_disease.concluding
                          ? $t("multidisciplinary_board.lbl_modify")
                          : $t("multidisciplinary_board.lbl_composer")
                      }}
                    </button>
                  </div>
                  <div
                    v-if="mdt_disease.concluding && mdt_disease.status === 2"
                  >
                    <el-dropdown class="mr-3 w-full">
                      <button
                        class="flex items-center justify-between btn bg-pri text-white bd-pri gap-2"
                      >
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                          >
                            <path
                              fill="currentColor"
                              d="M12.021 2.772a2.445 2.445 0 1 1 3.458 3.457L14.207 7.5l.086.086a2 2 0 0 1 0 2.829l-1.44 1.439a.5.5 0 0 1-.707-.707l1.44-1.44a1 1 0 0 0 0-1.414l-.086-.086l-6.646 6.647a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.595-.643l1.25-3.75a.5.5 0 0 1 .12-.195l8.626-8.625Zm2.75.707a1.445 1.445 0 0 0-2.042 0L4.187 12.02l-.925 2.774l2.982-.745l8.527-8.527a1.445 1.445 0 0 0 0-2.043ZM3.217 16.867l.051.038C4.011 17.445 5.112 18 6.5 18c.743 0 1.424-.26 2.029-.604c.603-.344 1.163-.79 1.661-1.195l.117-.095c.464-.378.862-.701 1.228-.917c.403-.238.644-.268.807-.214c.265.088.416.293.679 1.169c.087.292.243.61.471.867c.229.257.569.49 1.008.49c.474 0 .943-.229 1.305-.442c.239-.141.495-.318.703-.46c.103-.07.193-.133.264-.18c.268-.173.494-.285.649-.353c.077-.034.136-.057.174-.07l.04-.014l.006-.002a.5.5 0 0 0-.281-.96h-.001l-.003.002l-.007.002l-.021.007l-.07.023a3.57 3.57 0 0 0-.24.096a5.602 5.602 0 0 0-.79.43c-.117.077-.23.154-.342.232a9.27 9.27 0 0 1-.589.385c-.341.202-.61.303-.797.303c-.06 0-.15-.03-.26-.154a1.34 1.34 0 0 1-.261-.49c-.24-.8-.5-1.556-1.32-1.83c-.588-.196-1.16.023-1.632.301c-.435.257-.892.63-1.337.992l-.13.106c-.502.407-1.004.804-1.526 1.102c-.52.296-1.027.473-1.534.473c-.746 0-1.396-.2-1.934-.47l-1.349.337ZM17.5 15.5l-.14-.48l.14.48Z"
                            />
                          </svg>
                        </span>
                        <span>
                          {{ $t("Ký") }}
                        </span>
                        <i
                          class="el-icon-arrow-down el-icon--right fs-16 fw-600"
                        ></i>
                      </button>

                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                          v-for="(item, index) in mdt_disease.concluding
                            .multilang"
                          :key="index"
                          :disabled="
                            checkMultiLangData(
                              LANGUAGE_CODES_FOR_DISEASE[Number(item.lang)]
                            ) || !item.file_name_printing
                          "
                        >
                          <div @click="openModalSignPDF(item)">
                            {{
                              $t(
                                `common.${appUtils.getLangCode(
                                  Number(item.lang)
                                )}`
                              )
                            }}
                          </div>
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                  <!-- <div :class="mdt_disease.concluding ? 'col-4' : 'col-12'">
                    <button
                      class="btn bg-pri bd-pri text-white w-100"
                      @click="handleOpenModalSendSign"
                    >
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fill="currentColor"
                            d="M12.021 2.772a2.445 2.445 0 1 1 3.458 3.457L14.207 7.5l.086.086a2 2 0 0 1 0 2.829l-1.44 1.439a.5.5 0 0 1-.707-.707l1.44-1.44a1 1 0 0 0 0-1.414l-.086-.086l-6.646 6.647a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.595-.643l1.25-3.75a.5.5 0 0 1 .12-.195l8.626-8.625Zm2.75.707a1.445 1.445 0 0 0-2.042 0L4.187 12.02l-.925 2.774l2.982-.745l8.527-8.527a1.445 1.445 0 0 0 0-2.043ZM3.217 16.867l.051.038C4.011 17.445 5.112 18 6.5 18c.743 0 1.424-.26 2.029-.604c.603-.344 1.163-.79 1.661-1.195l.117-.095c.464-.378.862-.701 1.228-.917c.403-.238.644-.268.807-.214c.265.088.416.293.679 1.169c.087.292.243.61.471.867c.229.257.569.49 1.008.49c.474 0 .943-.229 1.305-.442c.239-.141.495-.318.703-.46c.103-.07.193-.133.264-.18c.268-.173.494-.285.649-.353c.077-.034.136-.057.174-.07l.04-.014l.006-.002a.5.5 0 0 0-.281-.96h-.001l-.003.002l-.007.002l-.021.007l-.07.023a3.57 3.57 0 0 0-.24.096a5.602 5.602 0 0 0-.79.43c-.117.077-.23.154-.342.232a9.27 9.27 0 0 1-.589.385c-.341.202-.61.303-.797.303c-.06 0-.15-.03-.26-.154a1.34 1.34 0 0 1-.261-.49c-.24-.8-.5-1.556-1.32-1.83c-.588-.196-1.16.023-1.632.301c-.435.257-.892.63-1.337.992l-.13.106c-.502.407-1.004.804-1.526 1.102c-.52.296-1.027.473-1.534.473c-.746 0-1.396-.2-1.934-.47l-1.349.337ZM17.5 15.5l-.14-.48l.14.48Z"
                          />
                        </svg>
                      </span>
                      {{ $t("multidisciplinary_board.btn_sign") }}
                    </button>
                  </div> -->
                  <div
                    class="col-12"
                    v-if="
                      !isOwner &&
                      (!mdt_disease.concluding || !mdt_disease.concluding.pdf)
                    "
                  >
                    <p class="fs-16 text-center txt-grey-600">
                      {{ $t("multidisciplinary_board.lbl_no_data") }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 mb-3" v-if="mdt_disease.type">
            <div class="bg--E7EAF5 p-3 radius-10 d-flex">
              <svg
                width="60"
                height="60"
                viewBox="0 0 60 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M35 5H15C12.25 5 10.025 7.25 10.025 10L10 50C10 52.75 12.225 55 14.975 55H45C47.75 55 50 52.75 50 50V20L35 5ZM40 45H20V40H40V45ZM40 35H20V30H40V35ZM32.5 22.5V8.75L46.25 22.5H32.5Z"
                  fill="#20409B"
                />
              </svg>

              <div class="w-100 pl-3 cc-tt-content">
                <div class="flex justify-between items-center">
                  <p class="fs-24 fw-500 mb-1 txt-grey-900 max-line-1">
                    {{ $t("multidisciplinary_board.lbl_surgery_report") }}
                  </p>
                  <p
                    v-if="mdt_disease.operation && mdt_disease.operation.sign"
                    class="signed-class"
                  >
                    Đã ký
                  </p>
                </div>

                <p class="fs-14 txt-grey-800" v-if="mdt_disease.operation">
                  <span>
                    {{ $t("multidisciplinary_board.lbl_last_update") }}:
                    {{ formatHmDMY(mdt_disease.operation.updated_at) }}
                  </span>
                </p>
                <div class="flex gap-2 items-center">
                  <div
                    class="w-full"
                    v-if="
                      mdt_disease.operation &&
                      mdt_disease.operation.file_name_printing
                    "
                  >
                    <button
                      class="btn bg-inherit bd-pri border txt-pri w-100"
                      @click="
                        handleOpenMdtConcluding(
                          mdt_disease.operation,
                          ENVELOPE_DOCUMENT_TYPE.OPERATION_FORM
                        )
                      "
                    >
                      <span>
                        <svg
                          width="25"
                          height="24"
                          viewBox="0 0 25 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.75 6.5C16.54 6.5 19.92 8.63 21.57 12C19.92 15.37 16.54 17.5 12.75 17.5C8.96 17.5 5.58 15.37 3.93 12C5.58 8.63 8.96 6.5 12.75 6.5ZM12.75 4.5C7.75 4.5 3.48 7.61 1.75 12C3.48 16.39 7.75 19.5 12.75 19.5C17.75 19.5 22.02 16.39 23.75 12C22.02 7.61 17.75 4.5 12.75 4.5ZM12.75 9.5C14.13 9.5 15.25 10.62 15.25 12C15.25 13.38 14.13 14.5 12.75 14.5C11.37 14.5 10.25 13.38 10.25 12C10.25 10.62 11.37 9.5 12.75 9.5ZM12.75 7.5C10.27 7.5 8.25 9.52 8.25 12C8.25 14.48 10.27 16.5 12.75 16.5C15.23 16.5 17.25 14.48 17.25 12C17.25 9.52 15.23 7.5 12.75 7.5Z"
                            fill="#20419B"
                          />
                        </svg>
                      </span>
                      {{ $t("multidisciplinary_board.lbl_view") }}
                    </button>
                  </div>

                  <div
                    class="w-full"
                    v-if="isOwner && mdt_disease.status !== 2"
                  >
                    <button
                      class="btn bg-pri bd-pri text-white w-100"
                      @click="showTTTQM(true)"
                    >
                      <span>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.99902 17.2505V21.0005H6.74902L17.809 9.94055L14.059 6.19055L2.99902 17.2505Z"
                            fill="white"
                          />
                          <path
                            d="M20.709 5.63055L18.369 3.29055C17.979 2.90055 17.349 2.90055 16.959 3.29055L15.129 5.12055L18.879 8.87055L20.709 7.04055C21.099 6.65055 21.099 6.02055 20.709 5.63055Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                      {{
                        mdt_disease.operation
                          ? $t("multidisciplinary_board.lbl_modify")
                          : $t("multidisciplinary_board.lbl_composer")
                      }}
                    </button>
                  </div>
                  <div
                    v-if="mdt_disease.operation && mdt_disease.status === 2"
                    class="w-full"
                    @click="openModalOperationSignPDF(mdt_disease.operation)"
                  >
                    <button
                      class="flex items-center justify-between btn bg-pri text-white bd-pri gap-2"
                    >
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fill="currentColor"
                            d="M12.021 2.772a2.445 2.445 0 1 1 3.458 3.457L14.207 7.5l.086.086a2 2 0 0 1 0 2.829l-1.44 1.439a.5.5 0 0 1-.707-.707l1.44-1.44a1 1 0 0 0 0-1.414l-.086-.086l-6.646 6.647a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.595-.643l1.25-3.75a.5.5 0 0 1 .12-.195l8.626-8.625Zm2.75.707a1.445 1.445 0 0 0-2.042 0L4.187 12.02l-.925 2.774l2.982-.745l8.527-8.527a1.445 1.445 0 0 0 0-2.043ZM3.217 16.867l.051.038C4.011 17.445 5.112 18 6.5 18c.743 0 1.424-.26 2.029-.604c.603-.344 1.163-.79 1.661-1.195l.117-.095c.464-.378.862-.701 1.228-.917c.403-.238.644-.268.807-.214c.265.088.416.293.679 1.169c.087.292.243.61.471.867c.229.257.569.49 1.008.49c.474 0 .943-.229 1.305-.442c.239-.141.495-.318.703-.46c.103-.07.193-.133.264-.18c.268-.173.494-.285.649-.353c.077-.034.136-.057.174-.07l.04-.014l.006-.002a.5.5 0 0 0-.281-.96h-.001l-.003.002l-.007.002l-.021.007l-.07.023a3.57 3.57 0 0 0-.24.096a5.602 5.602 0 0 0-.79.43c-.117.077-.23.154-.342.232a9.27 9.27 0 0 1-.589.385c-.341.202-.61.303-.797.303c-.06 0-.15-.03-.26-.154a1.34 1.34 0 0 1-.261-.49c-.24-.8-.5-1.556-1.32-1.83c-.588-.196-1.16.023-1.632.301c-.435.257-.892.63-1.337.992l-.13.106c-.502.407-1.004.804-1.526 1.102c-.52.296-1.027.473-1.534.473c-.746 0-1.396-.2-1.934-.47l-1.349.337ZM17.5 15.5l-.14-.48l.14.48Z"
                          />
                        </svg>
                      </span>
                      <span>
                        {{ $t("Ký") }}
                      </span>
                    </button>
                  </div>
                  <div
                    class="w-full"
                    v-if="
                      !isOwner &&
                      (!mdt_disease.operation ||
                        !mdt_disease.operation.file_name_printing)
                    "
                  >
                    <p class="fs-16 text-center txt-grey-600">
                      {{ $t("multidisciplinary_board.lbl_no_data") }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- !kết luận -->
        <div class="mb-5 row">
          <div class="col-md-6">
            <p class="fs-18 fw-600 txt-grey-900 mb-2">
              {{ $t("multidisciplinary_board.lbl_patient_info") }}
            </p>
            <div class="d-flex">
              <p class="fs-16 mb-0 w-75 max-line-1 fw-500 txt-grey-900">
                {{ mdtUtils.getMdtPersonInfoString(mdt_disease) }}
              </p>
            </div>
          </div>
          <div class="col-md-6 pl-3 border-left">
            <p class="fs-18 fw-600 txt-grey-900 mb-2">
              {{ $t("health_book_page.lbl_doctor") }}
            </p>
            <p class="fs-16 mb-0 w-75 max-line-1 fw-500 txt-grey-900">
              {{ mdt_disease.doctor && mdt_disease.doctor.name }}
            </p>
          </div>
        </div>

        <!-- Tài liệu -->
        <div class="mb-3">
          <div class="row">
            <div class="col-md-6" :set="(tests = fileAttachTest)">
              <div
                class="test-wraper cursor-pointer"
                @click="goToDocument(tests.length)"
              >
                <div class="d-flex align-items-center justify-content-between">
                  <div class="d-flex align-items-center">
                    <svg
                      width="40"
                      height="41"
                      viewBox="0 0 40 41"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="mr-2"
                    >
                      <path
                        d="M32 12H20L17 9H8C6.35 9 5.015 10.35 5.015 12L5 30C5 31.65 6.35 33 8 33H32C33.65 33 35 31.65 35 30V15C35 13.35 33.65 12 32 12Z"
                        fill="#FFA043"
                      />
                    </svg>

                    <div>
                      <p class="fs-16 fw-500 txt-black mb-2">
                        {{ $t("multidisciplinary_board.lbl_doc_test") }}
                      </p>
                      <p class="fs-24 fw-500 txt-pri mb-0">
                        ({{ (tests && tests.length) || 0 }})
                      </p>
                    </div>
                  </div>
                  <button class="btn-add-s" type="button" v-if="isOwner">
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="mr-2"
                    >
                      <path
                        d="M15.9998 29.3337C23.3332 29.3337 29.3332 23.3337 29.3332 16.0003C29.3332 8.66699 23.3332 2.66699 15.9998 2.66699C8.6665 2.66699 2.6665 8.66699 2.6665 16.0003C2.6665 23.3337 8.6665 29.3337 15.9998 29.3337Z"
                        stroke="#20419B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.6665 16H21.3332"
                        stroke="#20419B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M16 21.3337V10.667"
                        stroke="#20419B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span class="fs-16 fw-500 txt-pri">
                      {{ $t("multidisciplinary_board.btn_add") }}
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-md-6" :set="(pacs = fileAttachPacs)">
              <div
                class="test-wraper cursor-pointer"
                @click="goToPacs(pacs.length)"
              >
                <div class="d-flex align-items-center justify-content-between">
                  <div class="d-flex align-items-center">
                    <svg
                      width="40"
                      height="41"
                      viewBox="0 0 40 41"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="mr-2"
                    >
                      <path
                        d="M32 12H20L17 9H8C6.35 9 5.015 10.35 5.015 12L5 30C5 31.65 6.35 33 8 33H32C33.65 33 35 31.65 35 30V15C35 13.35 33.65 12 32 12Z"
                        fill="#FFA043"
                      />
                    </svg>

                    <div>
                      <p class="fs-16 fw-500 txt-black mb-2">
                        {{ $t("multidisciplinary_board.lbl_image_analyst") }}
                      </p>
                      <p class="fs-24 fw-500 txt-pri mb-0">
                        ({{ (pacs && pacs.length) || 0 }})
                      </p>
                    </div>
                  </div>
                  <button class="btn-add-s" type="button" v-if="isOwner">
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="mr-2"
                    >
                      <path
                        d="M15.9998 29.3337C23.3332 29.3337 29.3332 23.3337 29.3332 16.0003C29.3332 8.66699 23.3332 2.66699 15.9998 2.66699C8.6665 2.66699 2.6665 8.66699 2.6665 16.0003C2.6665 23.3337 8.6665 29.3337 15.9998 29.3337Z"
                        stroke="#20419B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.6665 16H21.3332"
                        stroke="#20419B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M16 21.3337V10.667"
                        stroke="#20419B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span class="fs-16 fw-500 txt-pri">
                      {{ $t("multidisciplinary_board.btn_add") }}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- !Tài liệu -->

        <!-- Multilang -->
        <div class="flex items-center py-3">
          <svg
            class="mr-1"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.41 8.64C21.41 8.64 21.41 8.64 21.41 8.59C20.7054 6.66623 19.4269 5.00529 17.7475 3.83187C16.0681 2.65845 14.0688 2.02917 12.02 2.02917C9.97126 2.02917 7.97195 2.65845 6.29252 3.83187C4.6131 5.00529 3.33461 6.66623 2.63001 8.59C2.63001 8.59 2.63001 8.59 2.63001 8.64C1.84314 10.8109 1.84314 13.1891 2.63001 15.36C2.63001 15.36 2.63001 15.36 2.63001 15.41C3.33461 17.3338 4.6131 18.9947 6.29252 20.1681C7.97195 21.3416 9.97126 21.9708 12.02 21.9708C14.0688 21.9708 16.0681 21.3416 17.7475 20.1681C19.4269 18.9947 20.7054 17.3338 21.41 15.41C21.41 15.41 21.41 15.41 21.41 15.36C22.1969 13.1891 22.1969 10.8109 21.41 8.64ZM4.26001 14C3.91324 12.6892 3.91324 11.3108 4.26001 10H6.12001C5.96004 11.3285 5.96004 12.6715 6.12001 14H4.26001ZM5.08001 16H6.48001C6.71473 16.8918 7.05022 17.7541 7.48001 18.57C6.49931 17.9019 5.67951 17.0241 5.08001 16ZM6.48001 8H5.08001C5.67088 6.97909 6.4802 6.10147 7.45001 5.43C7.03057 6.24725 6.70515 7.10942 6.48001 8ZM11 19.7C9.77178 18.7987 8.90916 17.4852 8.57001 16H11V19.7ZM11 14H8.14001C7.9534 12.6732 7.9534 11.3268 8.14001 10H11V14ZM11 8H8.57001C8.90916 6.51477 9.77178 5.20132 11 4.3V8ZM18.92 8H17.52C17.2853 7.10816 16.9498 6.24594 16.52 5.43C17.5007 6.09807 18.3205 6.97594 18.92 8ZM13 4.3C14.2282 5.20132 15.0909 6.51477 15.43 8H13V4.3ZM13 19.7V16H15.43C15.0909 17.4852 14.2282 18.7987 13 19.7ZM15.86 14H13V10H15.86C16.0466 11.3268 16.0466 12.6732 15.86 14ZM16.55 18.57C16.9798 17.7541 17.3153 16.8918 17.55 16H18.95C18.3505 17.0241 17.5307 17.9019 16.55 18.57ZM19.74 14H17.88C17.9613 13.3365 18.0014 12.6685 18 12C18.0011 11.3315 17.961 10.6636 17.88 10H19.74C20.0868 11.3108 20.0868 12.6892 19.74 14Z"
              fill="#41464B"
            />
          </svg>
          <span class="fs-16 fw-600 mr-3 text-black">
            {{ $t("common.select_lang") }}
          </span>
          <el-dropdown class="mr-3">
            <span class="el-dropdown-link text-primary">
              <span class="fs-16 fw-600">{{ showLang }}</span>
              <i class="el-icon-arrow-down el-icon--right fs-16 fw-600"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(langCode, index) in Object.keys(
                  LANGUAGE_CODES_FOR_DISEASE
                )"
                :key="index"
                :disabled="
                  checkMultiLangData(LANGUAGE_CODES_FOR_DISEASE[langCode])
                "
              >
                <div @click="setLang(LANGUAGE_CODES_FOR_DISEASE[langCode])">
                  {{ $t(`common.${langCode.toLowerCase()}`) }}
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <!-- <a
              href="javascript:;"
              class="mr-3"
              @click="($event) => addFieldsLang"
              >+ {{ $t("multidisciplinary_board.btn_add") }}</a
          >-->

          <el-tooltip
            :content="
              $t(
                'multidisciplinary_board.lbl_tooltip_multi_lang_select_case_info'
              )
            "
            placement="top"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 17H11V11H13V17ZM13 9H11V7H13V9Z"
                fill="black"
              />
            </svg>
          </el-tooltip>
        </div>

        <!-- Multilang -->
        <div class="mb-3" v-if="multilang">
          <div class="row mb-3">
            <p class="fs-16 fw-500 txt-black mb-1">
              {{ $t("multidisciplinary_board.lbl_admission_date") }}
            </p>
            <p class="pre-wrap fs-14 mb-0 txt-black" v-if="mdt_disease">
              {{
                mdt_disease.admission_date &&
                formatDate(mdt_disease.admission_date)
              }}
            </p>
          </div>
          <div class="row mb-3">
            <p class="fs-16 fw-500 txt-black mb-1">
              {{ $t("multidisciplinary_board.lbl_diagnose") }}
            </p>
            <p class="pre-wrap fs-14 mb-0 txt-black">
              {{ multilang.patient_diagnostic }}
            </p>
          </div>
          <div class="row mb-3">
            <p class="fs-16 fw-500 txt-black mb-1">
              {{ $t("multidisciplinary_board.lbl_past_treatments") }}
            </p>
            <div v-html="multilang.indication"></div>
          </div>
          <div class="row mb-3">
            <p class="fs-16 fw-500 txt-black mb-1">
              {{ $t("multidisciplinary_board.lbl_treatment_his") }}
            </p>
            <div v-html="multilang.patient_diseases_current_cure"></div>
          </div>
          <div class="row mb-3">
            <p class="fs-16 fw-500 txt-black mb-1">
              {{ $t("multidisciplinary_board.lbl_conclude") }}
            </p>
            <div
              class="pre-wrap fs-14 mb-0 txt-black"
              v-html="multilang.conclusion"
            ></div>
          </div>
          <div class="row mb-3">
            <p class="fs-16 fw-500 txt-black mb-1">
              {{ $t("multidisciplinary_board.lbl_treatment") }}
            </p>
            <div v-html="multilang.treatment"></div>
          </div>
        </div>
        <!-- HSSK Liên quan (Ca bệnh) -->
      </div>
    </div>

    <div
      class="modal fade"
      id="modalTTTQM"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalTTTQMLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" role="document" v-show="mdt_disease">
        <div v-loading="loadingTQM" class="modal-content p-3">
          <div class="d-flex justify-content-between pb-3 border-bottom mb-3">
            <h5 class="modal-title" id="modalTTTQMLabel">
              {{ $t("multidisciplinary_board.lbl_surgery_report") }}
            </h5>
            <button
              type="button"
              class="close bg-white border-0"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" class="fs-18 fw-500">&times;</span>
            </button>
          </div>
          <TTTQMForm
            ref="ModalAddTQM"
            :curr_disease="mdt_disease"
            :room_info="mdt_room"
            @refresh="refreshTTTQm"
            @onLoading="(isLoading) => (loadingTQM = isLoading)"
          />
        </div>
      </div>
    </div>
    <BienBan
      ref="ModalAddBienBan"
      :mdtRoomMembers="mdtRoomMembers"
      :currDisease="mdt_disease"
      :room_info="mdt_room"
      @refresh="refreshDiseaseConcluding"
      :checkin_members="checkin_members"
    />
    <ModalSendCaseToOrg ref="ModalSendCaseToOrg" :mdt_disease="mdt_disease" />
    <!-- <ModalBienBanHC
      :concluding_data="mdt_disease && mdt_disease.concluding"
      :operation_data="mdt_disease && mdt_disease.operation"
      @refresh="showMdtDiseases"
    />-->
    <ModalCopyMdtDisease
      ref="ModalCopyMdtDisease"
      :clinicId="mdt_room && mdt_room.clinic_id"
      :mdtDiseaseId="mdt_disease.id"
      @onCopySuccess="handleCopySuccess"
    />
    <!-- <ModalSendSign
      v-if="pdfViewerID"
      :ref="`${pdfViewerID}_MDTModalSignPDF`"
      :containerIDProps="`${pdfViewerID}_MDTContainerSendSignID`"
      :elementIDProps="`${pdfViewerID}_MDTSendSignID`"
      :imageIDProps="`${pdfViewerID}_MDTSendSignImageID`"
      @onSuccess="showMdtDiseases"
    /> -->
    <ModalSignPDF
      v-if="pdfViewerID"
      :ref="`${pdfViewerID}_MDTModalSignPDF`"
      :containerIDProps="`${pdfViewerID}_MDTContainerSignPDFID`"
      :elementIDProps="`${pdfViewerID}_MDTSignPDFID`"
      :imageIDProps="`${pdfViewerID}_MDTSignPDFImageID`"
      :envelopeName="envelopeName"
      @onSuccess="handleSignDocumentSuccess"
    />
    <PdfViewer
      v-if="pdfViewerID"
      :ref="`${pdfViewerID}_MdtPdfViewer`"
      :containerIDProps="`${pdfViewerID}_ContainerMdtPDFViewerID`"
      :elementIDProps="`${pdfViewerID}_MdtPDFViewerID`"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import appUtils from '../../utils/appUtils'
import mdtUtils from '../../utils/mdtUtils'
import TTTQMForm from '../../components/HoiChan/TTTQMForm.vue'
import BienBan from '../../components/HoiChan/BienBan.vue'
import ModalSendCaseToOrg from '../../components/HoiChan/ModalSendCaseToOrg.vue'
import ModalBienBanHC from '../../components/ElectronicMedicalRecords/ModalBienBanHC.vue'
import ModalCopyMdtDisease from './components/ModalCopyMdtDisease.vue'
import { LANGUAGE_CODES_FOR_DISEASE } from '../../lang'
import { mapState } from 'vuex'
import { SIGN_MDT_DISEASE } from '../../constants/appConstant'
import ModalSendSign from '../../components/Signature/ModalSendSign.vue'
import {
  ENVELOPE_DOCUMENT_TYPE,
  ENVELOPE_NAME_TYPE,
  SIGN_ENVELOPE_STATUS
} from '../../utils/constants'
import SignatureRequest from '../../api/request/SignatureRequest'
import PdfViewer from '../../components/PdfViewer/PdfViewer.vue'
import ModalSignPDF from '../../components/Signature/ModalSignPDF.vue'
import uploadS3File from '../../utils/uploadS3File'
import { mixinSignPdf } from '../../utils/mixinSignPdf'

export default {
  name: 'MdtDiseaseDetail',
  mixins: [mixinSignPdf],
  components: {
    TTTQMForm,
    BienBan,
    ModalSendCaseToOrg,
    ModalBienBanHC,
    ModalCopyMdtDisease,
    ModalSendSign,
    PdfViewer,
    ModalSignPDF
  },
  data () {
    return {
      file_attach: [],
      mr_id: null,
      mdt_disease: {},
      mdtUtils,
      doctor_id: null,
      mdt_room: null,
      room_name: '',
      checkin_members: [],
      sended: false,
      dtr_member: {},
      multilang: null,
      langVi: null,
      langFr: null,
      langEn: null,
      LANGUAGE_CODES_FOR_DISEASE,
      appUtils,
      loading: false,
      loadingTQM: false,
      pdfViewerID: '',
      mdtRoomMembers: [],
      operationInfo: {},
      ENVELOPE_DOCUMENT_TYPE,
      emrDetail: {}
    }
  },
  computed: {
    fileAttachTest () {
      return this.file_attach.filter((f) => f.type === 1 || f.type === 2)
    },
    fileAttachPacs () {
      return this.file_attach.filter((f) => f.type === 3)
    },
    isOwner () {
      // return this.doctor_id === this.mdt_disease?.doctor_id
      return true
    },
    master () {
      return this.dtr_member?.role === 4 || this.dtr_member?.role === 1
    },
    showLang () {
      if (this.multilang) {
        const lang = appUtils.getLangCode(this.multilang.lang)
        this.lang = lang
        return this.$t(`common.${lang}`)
      }
    },
    hasSigned () {
      return (
        this.mdt_disease &&
        (this.mdt_disease.concluding?.sign || this.mdt_disease.operation?.sign)
      )
    },
    envelopeName () {
      return this.handleGetEnvelopeName(ENVELOPE_NAME_TYPE.emr, this.emrDetail)
    },
    ...mapState({
      mdtRoomStore: (state) => state.mdtStore.mdtRoomDetail || {}
    })
  },
  mounted () {
    this.showMdtDiseases()
    this.doctor_id = appUtils.getLocalUser()?.doctor?.id
  },
  methods: {
    async getMedicalRecordMaster (id) {
      if (!id) return

      const self = this
      self.loading = true

      try {
        const resp = await self.$rf
          .getRequest('DoctorRequest')
          .getDetailBADT(id)

        if (!resp?.data) {
          return
        }

        this.emrDetail = resp.data || {}

        const medicalRecordMain = resp.data?.medical_record_master
        const person = {
          ...resp.data.person,
          medical_record_name: resp.data.medical_record_name,
          treat_doctor: resp.data.treat_doctor_name,
          main_doctor: resp.data.main_doctor_name,
          status: resp.data.status,
          ref_id: resp.data.ref_id,
          person_ref_id: resp.data.person_ref_id
        }
        if (medicalRecordMain && medicalRecordMain.admission_room) {
          person.admission_room = medicalRecordMain.admission_room
        }
        if (medicalRecordMain && medicalRecordMain.admission_bed) {
          person.admission_bed = medicalRecordMain.admission_bed
        }

        self.$store.commit('setPerson', { person })
      } catch (error) {
        console.log(error)
      } finally {
        self.loading = false
      }
    },
    checkMultiLangData (langVal) {
      if (langVal && this.mdt_disease.multilang) {
        const data = _.find(this.mdt_disease.multilang, { lang: langVal })
        if (!data) {
          return true
        } else {
          return false
        }
      } else if (
        langVal &&
        this.multilang !== null &&
        typeof this.multilang !== 'undefined'
      ) {
        if (this.multilang?.lang !== langVal) {
          return true
        } else {
          return false
        }
      }
    },
    setLang (value) {
      if (value) {
        const data = _.find(this.mdt_disease.multilang, { lang: value })
        this.multilang = data
      }
    },
    closeMR () {
      if (this.$route.query.type === 'TQM') {
        this.$router.push({
          name: 'ERMTreeView',
          params: { id: this.mr_id },
          query: { tab: 'TTTQM' }
        })
      }
      if (this.$route.query.type === 'bienban') {
        this.$router.push({
          name: 'ERMTreeView',
          params: { id: this.mr_id },
          query: { tab: 'BienBanHoiChan' }
        })
      }
    },
    async showMdtDiseases () {
      let self = this
      self.loading = true

      await self.$rf
        .getRequest('DoctorRequest')
        .showMdtDiseases(this.$route.params.id)
        .then(async (r) => {
          _.find()
          await this.getMdtMembers(r.data)

          self.mdt_disease = r.data
          console.log('mdt_disease', self.mdt_disease)
          self.mr_id = self.mdt_disease?.mr_id
          self.file_attach = r.data.attachment
          await self.showMdtRoom(r.data.mdt_room_id)
          // self.getMdtCheckinMembers(r.data.mdt_room_id)
          self.sended = r.data?.public_mdt_case?.find(
            (c) =>
              window.moment(c.mdt_date + ' 23:59:59', 'YYYY-MM-DD HH:mm:ss') >=
              window.moment()
          )

          const defaultData = {
            lang: appUtils.getLangId(this.$lang),
            patient_diagnostic: self.mdt_disease?.patient_diagnostic,
            patient_diseases_current_cure:
              self.mdt_disease?.patient_diseases_current_cure,
            conclusion: self.mdt_disease?.conclusion,
            treatment: self.mdt_disease?.treatment,
            indication: self.mdt_disease?.indication
          }

          if (self.mdt_disease?.multilang.length > 0) {
            let data = _.find(self.mdt_disease?.multilang, {
              lang: appUtils.getLangId(this.$lang)
            })

            if (!data) {
              data = self.mdt_disease?.multilang[0]
            }

            self.multilang = data
          } else {
            self.multilang = defaultData
          }

          await this.handleGetTTTQM()
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          self.loading = false
        })

      if (this.mdt_disease && this.mdt_disease?.medical_record?.id) {
        await this.getMedicalRecordMaster(this.mdt_disease?.medical_record?.id)
      }

      // this.handleGetEnvelopeByDocumentID()
    },
    onClose () {
      if (this.mdt_disease) {
        this.$router.push({
          name: 'ListMdt',
          query: { mdtid: this.mdt_disease.mdt_room_id }
        })
      }
    },
    onUpdate () {
      this.$router.push({
        name: 'UpdateMdtDisease',
        params: { id: this.mdt_disease.id }
      })
    },
    goToDocument () {
      this.$router.push({
        name: 'MDTDDocument',
        params: { id: this.mdt_disease.id }
      })
    },
    goToPacs () {
      this.$router.push({
        name: 'MdtDiseasePacs',
        params: { id: this.mdt_disease.id }
      })
    },
    viewDetail () {
      window.open(
        `#/doctor/mdt-room/${this.mdt_disease.mdt_room_id}/disease/${this.mdt_disease.person_diseases_id}`
      )
    },
    formatDate (date) {
      return appUtils.formatDate(date)
    },
    async openUrl ({ urlFile, print = false }) {
      if (!urlFile) {
        this.$toast.open({
          message: this.$t('multidisciplinary_board.lbl_notfound'),
          type: 'error'
        })
        return
      }
      window.open(urlFile)

      // try {
      //   const res = await this.$rf.getRequest('DoctorRequest').getEmrDocument({
      //     fileName
      //   })
      //   if (res.data) {
      //     // var encodedUrl = encodeURIComponent(res.data?.documentLink)
      //     // var iFrameUrl = `https://docs.google.com/viewerng/viewer?url=${encodedUrl}`

      //     if (print) {
      //       const printWindow = window.open(res.data?.documentLink)
      //       printWindow.print()

      //       printWindow.onafterprint = function () {
      //         printWindow.close()
      //       }
      //     } else {
      //       window.open(res.data?.documentLink)
      //     }
      //   }
      // } catch (error) {
      //   console.log(error)
      // }
    },
    formatHmDMY (dt) {
      return window.moment(dt).format('HH:mm - DD/MM/YYYY')
    },
    async showMdtRoom (id) {
      let self = this
      if (self.mdtRoomStore?.id === Number(id)) {
        const clonedMdtRoom = { ...self.mdtRoomStore }

        self.mdt_room = clonedMdtRoom
        self.room_name = clonedMdtRoom?.room?.room_name
        self.dtr_member = clonedMdtRoom?.doctor

        return clonedMdtRoom
      } else {
        self.$rf
          .getRequest('DoctorRequest')
          .getMDTRoomDetail(id)
          .then((r) => {
            self.mdt_room = r.data
            self.room_name = r.data?.room?.room_name
            self.dtr_member = r?.data?.doctor
            this.$store.commit('mdtStore/setMdtRoomDetail', r.data)
          })
      }
    },
    async getMdtCheckinMembers (id) {
      let self = this
      let params = {
        date: window.moment().format('YYYY-MM-DD'),
        mdt_room_id: id,
        limit: 1000
      }
      self.$rf
        .getRequest('DoctorRequest')
        .getMdtCheckinMembers(params)
        .then((r) => {
          self.checkin_members = r.data
        })
    },
    refreshTTTQm () {
      this.showMdtDiseases()
      this.showTTTQM(false)
      this.loading = false
      this.loadingTQM = false
    },
    showTTTQM (show) {
      window.$('#modalTTTQM').modal(show ? 'show' : 'hide')
      this.$refs.ModalAddTQM.openModal(show)
    },
    viewTTTQM () {
      if (this.mdt_disease?.operation) {
        window.open(this.mdt_disease.operation.file_name_printing)
      }
    },
    refreshDiseaseConcluding () {
      this.showMdtDiseases()
      this.showDiseaseConcluding(false)
    },
    showDiseaseConcluding (show) {
      this.$refs.ModalAddBienBan.openModal(show)
      // window.$('#modalAddBienBan').modal(show ? 'show' : 'hide')
    },
    backToMdtDetail () {
      this.$router.push({
        name: 'ListMdt',
        query: { mdtid: this.mdt_room?.id }
      })
    },
    backToMdtSession (id) {
      this.$router.push({ name: 'SessionDetail', params: { id: id } })
    },
    onSenToOrg (d) {
      this.mdt_to_send = d
      this.showModalSend(true)
    },
    showModalSend (s) {
      this.$refs.ModalSendCaseToOrg.openModal(s)
      // window.$('#modalSendCaseToOrg').modal(s ? 'show' : 'hide')
    },
    onShowModalSendToMR (s) {
      if (!this.mdt_disease?.concluding && !this.mdt_disease.operation) {
        alert('Chưa có biên bản nào!')
        return
      }
      if (
        (this.mdt_disease?.concluding?.medical_record_id &&
          this.mdt_disease.operation?.medical_record_id) ||
        (!this.mdt_disease?.concluding &&
          this.mdt_disease.operation?.medical_record_id) ||
        (!this.mdt_disease.operation &&
          this.mdt_disease?.concluding?.medical_record_id)
      ) {
        alert('Tất cả biên bản đã được chuyển!')
        return
      }
      window.$('#modal__chuyen_bien_ban_den_badt').modal(s ? 'show' : 'hide')
    },
    enaledSendToMr () {
      if (!this.mdt_disease?.concluding && !this.mdt_disease?.operation) {
        return false
      }
      if (
        (this.mdt_disease?.concluding?.medical_record_id &&
          this.mdt_disease.operation?.medical_record_id) ||
        (!this.mdt_disease?.concluding &&
          this.mdt_disease.operation?.medical_record_id) ||
        (!this.mdt_disease.operation &&
          this.mdt_disease?.concluding?.medical_record_id)
      ) {
        return false
      } else {
        return true
      }
    },
    showModalCopyMdtDisease (show) {
      this.$refs.ModalCopyMdtDisease.openModal(show)
      // window.$('#modalCopyMdtDisease').modal(show ? 'show' : 'hide')
    },
    handleCopySuccess (newMdtDiseaseId) {
      if (!newMdtDiseaseId) return

      this.$router.push({
        name: 'UpdateMdtDisease',
        params: { id: newMdtDiseaseId }
      })
    },
    async handleUpdateStatusPatient (stt) {
      const r = confirm(
        this.$t('multidisciplinary_board.lbl_approve_confirm_msg')
      )

      if (!r) return

      const self = this

      if (!self.mdt_disease || isNaN(stt)) return

      try {
        const params = {
          status: stt
        }

        this.loading = true
        await self.$rf
          .getRequest('DoctorRequest')
          .mdtDiseaseStatus(self.mdt_disease.id, params)
          .then(() => {
            this.$message({
              message: this.$t('multidisciplinary_board.lbl_saved_msg'),
              type: 'success'
            })
            return true
          })

        this.refreshDiseaseConcluding()
      } catch (error) {
        console.log(error)
      } finally {
      }
    },
    async handleSignBBHC () {
      const params = {
        id: this.mdt_disease?.concluding?.id,
        type: SIGN_MDT_DISEASE.BBHC,
        method: 1
      }

      try {
        await this.$rf.getRequest('DoctorRequest').signMdtDiseaseDetail(params)

        this.$toast.open({
          message: this.$t('multidisciplinary_board.lbl_signed_msg'),
          type: 'success'
        })
        this.refreshDiseaseConcluding()
      } catch (error) {
        console.log({ error })
      }
    },

    async handleOpenMdtConcluding (item, docType) {
      const envelopeInfo = await this.handleGetEnvelopeByDocumentID(
        item,
        docType
      )

      const s3Url = await uploadS3File.getLinkVideoAWS(
        envelopeInfo?.document_url
      )

      if (s3Url) {
        this.pdfViewerID = item?.id
        const ref = `${this.pdfViewerID}_MdtPdfViewer`

        this.$nextTick(() => {
          this.$refs[ref].handleOpen(s3Url, docType, item.id, false, false)
          this.loading = false
        })
      } else {
        if (!item?.file_name_printing) {
          this.$toast.open({
            message: this.$t('multidisciplinary_board.lbl_notfound'),
            type: 'error'
          })
          return
        }
        window.open(item.file_name_printing)
      }
    },
    async getMdtMembers (data) {
      try {
        if (!data?.mdt_room_id) return

        let self = this
        let params = {}
        await self.$rf
          .getRequest('DoctorRequest')
          .getMdtMembers(data?.mdt_room_id, params)
          .then((r) => {
            self.mdtRoomMembers = r.data
          })
      } catch (error) {
        console.log(error)
      }
    },
    async openModalSignPDF (item) {
      let error
      let signer

      const documentLink = item?.file_name_printing

      if (!documentLink) {
        this.$toast.open({
          message: 'Không tìm thấy tài liệu cần ký',
          type: 'error'
        })
        return
      }

      const envelopeInfo = await this.handleCheckSignEnvelope(
        item,
        ENVELOPE_DOCUMENT_TYPE.MDT_FORM
      )

      if (envelopeInfo) return

      const procedure = await this.handleProcessProcedureData(
        (err, signerData) => {
          if (err) {
            error = true

            this.$toast.open({
              message: err,
              type: 'error'
            })
          } else {
            signer = signerData
          }
        },
        ENVELOPE_DOCUMENT_TYPE.MDT_FORM
      )

      if (error) return

      const hasPermissionSignatory = procedure?.signatories?.some(
        (item) => item?.user_id === this.$user?.id
      )

      if (!hasPermissionSignatory) {
        this.$toast.open({
          message: 'Bạn không có quyền ký tài liệu này',
          type: 'error'
        })
        return
      }

      this.pdfViewerID = item?.id
      const mdtRef = `${this.pdfViewerID}_MDTModalSignPDF`
      this.$nextTick(() => {
        this.$refs[mdtRef].handleOpen(
          documentLink,
          ENVELOPE_DOCUMENT_TYPE.MDT_FORM,
          Number(item.id),
          signer?.id,
          procedure?.signatories || [],
          null,
          procedure?.id
        )
      })
    },
    async getSignProcedures (docType) {
      try {
        const params = {
          page_num: 1,
          page_size: 1000,
          order: 'desc',
          sort_by: 'id',
          document_type: docType,
          ws_id: this.mdt_disease?.medical_record?.clinic_id
        }

        const request = new SignatureRequest()

        const response = await request.getSignProcedures(params)
        return response.data?.data?.[0]
      } catch (error) {
        console.log(error)
      }
    },
    getUserByRole (role, callback) {
      let user
      let signer
      switch (role) {
        case 'secretary':
          const secretary = this.mdt_disease?.doctor?.user
          if (!secretary) {
            // eslint-disable-next-line standard/no-callback-literal
            callback('Không tìm thấy thông tin Thư ký')
          } else {
            user = secretary
            if (user?.id === this.$user?.id && user?.id) {
              signer = user
              callback(null, signer)
            }
          }

          break
        case 'president':
          const president = this.mdtRoomMembers?.find(
            (item) => item?.role === 1
          )

          if (!president) {
            // eslint-disable-next-line standard/no-callback-literal
            callback('Không tìm thấy thông tin Chủ toạ')
          } else {
            user = president?.doctor?.user || {}
            if (user?.id === this.$user?.id && user?.id) {
              signer = user
              callback(null, signer)
            }
          }
          break

        default:
          break
      }

      return user
    },

    async handleProcessProcedureData (callback, docType) {
      const procedure = await this.getSignProcedures(docType)

      const signatories = procedure?.sign_nodes?.reduce((arr, node) => {
        const signs = node?.node_signatories?.map((item) => {
          const user =
            docType === ENVELOPE_DOCUMENT_TYPE.MDT_FORM
              ? this.getUserByRole(item?.user_role, callback)
              : this.getUserOperationByRole(item?.user_role, callback)

          return {
            ...item,
            user,
            user_id: user?.id,
            sign_node_id: node?.id
          }
        })

        return arr.concat(signs)
      }, [])

      return { ...procedure, signatories }
    },
    async handleCheckSignEnvelope (item, docType) {
      try {
        const signEnvelope = await this.handleGetEnvelopeByDocumentID(
          item,
          docType
        )

        if (!signEnvelope?.id) return

        const signatories = signEnvelope?.envelope_signatories?.map((item) => ({
          ...item,
          user: item?.user_info,
          user_id: item?.user_id,
          sign_node_id: item?.node_signatory_info?.sign_node_id
        }))

        const signerInfo = signEnvelope?.envelope_signatories?.find(
          (item) => item?.user_id === this.$user?.id
        )

        const s3Url = await uploadS3File.getLinkVideoAWS(
          signEnvelope?.document_url
        )

        this.pdfViewerID = item?.id

        if (signerInfo?.status === 2) {
          const mdtRef = `${this.pdfViewerID}_MdtPdfViewer`

          this.$nextTick(() => {
            this.$refs[mdtRef].handleOpen(
              s3Url,
              docType,
              Number(item.id),
              false,
              false
            )
          })
        } else {
          const mdtRef = `${this.pdfViewerID}_MDTModalSignPDF`

          this.$nextTick(() => {
            this.$refs[mdtRef].handleOpen(
              s3Url,
              docType,
              Number(item.id),
              signerInfo?.user_id,
              signatories || [],
              signEnvelope
            )
          })
        }

        return signEnvelope
      } catch (error) {
        console.log(error)
      }
    },
    async handleSignDocumentSuccess (_, envelopeData) {
      try {
        if (!envelopeData?.id) return
        const request = new SignatureRequest()
        const response = await request.getSignEnvelopeByID(envelopeData?.id)

        const envelopeStatusCompleted =
          response?.data?.status === SIGN_ENVELOPE_STATUS.completed

        if (envelopeStatusCompleted) {
          if (envelopeData?.document_type === ENVELOPE_DOCUMENT_TYPE.MDT_FORM) {
            this.handleSignBBHC()
          } else if (
            envelopeData?.document_type ===
            ENVELOPE_DOCUMENT_TYPE.OPERATION_FORM
          ) {
            this.handleSignTTTQM()
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    async openModalOperationSignPDF (operation) {
      let error
      let signer

      const documentLink = operation?.file_name_printing

      if (!documentLink) {
        this.$toast.open({
          message: 'Không tìm thấy tài liệu cần ký',
          type: 'error'
        })
        return
      }

      const envelopeInfo = await this.handleCheckSignEnvelope(
        operation,
        ENVELOPE_DOCUMENT_TYPE.OPERATION_FORM
      )

      if (envelopeInfo) return

      const procedure = await this.handleProcessProcedureData(
        (err, signerData) => {
          if (err) {
            error = true

            this.$toast.open({
              message: err,
              type: 'error'
            })
          } else {
            signer = signerData
          }
        },
        ENVELOPE_DOCUMENT_TYPE.OPERATION_FORM
      )

      if (error) return

      const hasPermissionSignatory = procedure?.signatories?.some(
        (item) => item?.user_id === this.$user?.id
      )

      if (!hasPermissionSignatory) {
        this.$toast.open({
          message: 'Bạn không có quyền ký tài liệu này',
          type: 'error'
        })
        return
      }

      this.pdfViewerID = operation?.id
      const mdtRef = `${this.pdfViewerID}_MDTModalSignPDF`
      this.$nextTick(() => {
        this.$refs[mdtRef].handleOpen(
          documentLink,
          ENVELOPE_DOCUMENT_TYPE.OPERATION_FORM,
          Number(operation.id),
          signer?.id,
          procedure?.signatories || [],
          null,
          procedure?.id
        )
      })
    },
    getUserOperationByRole (role, callback) {
      let user
      let signer
      switch (role) {
        case 'exam_doctor':
          const treatmentDoctor = this.operationInfo?.treatment_doctor_info
          if (!treatmentDoctor) {
            // eslint-disable-next-line standard/no-callback-literal
            callback('Không tìm thấy thông tin Bác sĩ điều trị')
          } else {
            user = treatmentDoctor || {}
            if (user?.id === this.$user?.id && user?.id) {
              signer = user
              callback(null, signer)
            }
          }

          break
        case 'dean_surgery':
          const deanSurgery = this.operationInfo?.gm_surgery_info

          if (!deanSurgery) {
            // eslint-disable-next-line standard/no-callback-literal
            callback('Không tìm thấy thông tin Trưởng khoa ngoại')
          } else {
            user = deanSurgery || {}
            if (user?.id === this.$user?.id && user?.id) {
              signer = user
              callback(null, signer)
            }
          }
          break

        case 'dean_anesthesiology':
          const deanAnesthesiology = this.operationInfo?.gm_sur_anes_info

          if (!deanAnesthesiology) {
            // eslint-disable-next-line standard/no-callback-literal
            callback('Không tìm thấy thông tin Trưởng khoa PT và GMHS')
          } else {
            user = deanAnesthesiology || {}
            if (user?.id === this.$user?.id && user?.id) {
              signer = user
              callback(null, signer)
            }
          }
          break

        default:
          break
      }

      return user
    },
    async handleSignTTTQM () {
      const params = {
        id: this.mdt_disease?.operation?.id,
        type: SIGN_MDT_DISEASE.TTTQM,
        method: 1
      }

      try {
        await this.$rf.getRequest('DoctorRequest').signMdtDiseaseDetail(params)

        this.$toast.open({
          message: this.$t('multidisciplinary_board.lbl_signed_msg'),
          type: 'success'
        })
        this.refreshTTTQm()
      } catch (error) {
        console.log({ error })
      }
    },
    async handleGetEnvelopeByDocumentID (item, docType) {
      try {
        const request = new SignatureRequest()
        const response = await request.getSignEnvelopeByDocumentID({
          doc_id: item.id,
          doc_type: docType
        })

        return response.data
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    async handleGetTTTQM () {
      try {
        if (!this.mdt_disease?.operation?.id) return
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getMdtOperationByID(this.mdt_disease?.operation?.id)
        this.operationInfo = response.data || {}
        this.mdt_disease = {
          ...this.mdt_disease,
          operation: {
            ...(this.mdt_disease?.operation || {}),
            ...response.data
          }
        }
        return response.data
      } catch (error) {
        console.log(error)
      } finally {
      }
    }
  }
}
</script>

<style scoped>
.pre-wrap {
  white-space: pre-wrap;
}

.test-wraper {
  width: 100%;
  max-width: 491px;
  border-radius: 10px;
  padding: 12px 12px 16px 12px;
  background-color: #f9f8f8;
}

.test-item {
  height: 254px;
  max-width: 467px;
  width: 100%;
  left: 12px;
  top: 12px;
  border-radius: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-bottom: 16px;
}

.test-item.has-item {
  background-color: #fff;
}

.test-item.hasnt-item {
  background: #d9d9d9;
}

.btn-add {
  height: 57px;
  width: 75%;
  border-radius: 10px;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
}

.btn-add-s {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  width: 109px;
  border-radius: 10px;
  padding: 8px 16px;
  border: 1px dashed #20419b;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
}

.concluding-wraper {
  height: 192px;
  background: #f9f8f8;
  border-radius: 10px;
}

.concluding-file-open {
  width: 100%;
  height: 100%;
  background: linear-gradient(
      0deg,
      rgba(54, 54, 54, 0.8),
      rgba(54, 54, 54, 0.8)
    ),
    url("../../../public/assets/images/MDT/bienban-bg.png");
  background-color: #363636;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-add-disease {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 48px;
  width: 100%;
  border-radius: 10px;
  padding: 8px;
  border: 1px dashed #20419b;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  background: #fff;
}

.btn {
  height: 37px;
}

.bg--E7EAF5 {
  background-color: #e7eaf5;
}

.bg-inherit {
  background-color: inherit;
}

.cc-tt-content {
  height: 111px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.hover-none:hover {
  background-color: #667085;
}

.signed-class {
  display: block;
  padding: 2px 15px;
  background: transparent;
  color: #20419b;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 400;
}
</style>
